@import 'src/styles/typography.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  .text {
    margin-left: 36px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerButtons {
  display: flex;
  gap: 50px;
}

.containerImg {
  height: 135px;
  width: 150px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.marginTen {
  margin-bottom: 0px;
}

.marginDouble {
  margin-bottom: 60px;
}
.thankYouText {
  width: 371px;
  height: 24px;
  left: 775px;
  top: 1525px;

  font-family: 'barlow', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;

  color: #717680;
}
.sentText {
  width: 292px;
  height: 54px;
  left: 814px;
  top: 1455px;

  font-family: 'Barlow', serif;
  font-style: italic;
  font-weight: 800;
  font-size: 40px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;

  color: #111c31;
}
