@import 'src/styles/common.scss';
@import 'src/styles/colors.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
  //width: 210px;

  input {
    @extend .basicInput;
    width: auto;
    margin-top: 0;
    margin-right: 16px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: $mainWhite;
    background-color: $mainBlue;
    border-radius: 3px;
    cursor: pointer;

    &.close {
      svg {
        transform: rotate(45deg);
      }
    }
  }
}
