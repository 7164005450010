$mainBlack: #111c31;
$mainWhite: #ffffff;

$mainRed: #ee3947;
$mainLightRed: #ffe7e8;

$mainGray: #717680;
$mainLightGray: #f3f6fa;

$mainBlue: #1781ff;
$mainLightBlue: #d4e0f0;
