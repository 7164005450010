@import 'src/styles/colors.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  flex: 1;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 53px 36px 43px 36px;
    background-color: $mainWhite;

    @include large {
      padding-left: 100px;
      padding-right: 100px;
    }

    > * {
      margin-bottom: 29px;
    }
  }
  .container {
    width: 100%;
  }
  .title {
    text-transform: uppercase;

    color: #ee3947;
    text-align: center;
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .subtitle {
    text-transform: uppercase;
    width: 100%;
    color: #111c31;
    height: 19px;

    font-family: 'Barlow';
    font-style: italic;
    font-weight: 800;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 0px;
    margin-bottom: 35px;
  }
  @media screen and (max-width: 800px) {
    .subtitle {
      padding-bottom: 50px;
    }
  }
}
