@import 'src/styles/typography.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  flex: 1;

  svg {
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .text {
    flex: 1;
    margin-left: 18px;
    margin-right: 18px;
    text-align: center;
  }

  @include large {
    display: flex;

    svg {
      display: initial;
      margin: unset;
    }

    .title {
      text-align: left;
    }
  }
}
