@import 'src/styles/colors.scss';

.basicInput {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-top: 14px;
  padding: 16px 24px;
  outline: none;
  border: none;
  border-radius: 3px;
  background-color: $mainLightGray;
  color: $mainBlack;

  &::placeholder,
  .placeholder {
    color: $mainGray;
  }

  &.error {
    border: 1px solid $mainRed;
    background-color: $mainLightRed;
  }

  &.dribbleError {
    animation-name: bounce;
    animation-duration: 0.5s;
    animation-delay: 0.25s;
  }
}

.basicOptions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0 0 3px 3px;
  background-color: $mainLightGray;

  > div {
    padding: 16px 24px;
    cursor: pointer;

    &:hover {
      background-color: $mainLightBlue;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
