@import 'src/styles/colors.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;

  &.center {
    justify-content: center;
  }

  .checkItem {
    margin: 0 18px 14px 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
