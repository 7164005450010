@import 'src/styles/common.scss';

.wrapper {
  @extend .basicInput;
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  margin-right: 18px;
  color: $mainGray;
  background-color: $mainLightGray;
  cursor: pointer;
  transition: all 0.25s;

  &:last-of-type {
    margin-right: 0;
  }

  .circle {
    width: 12px;
    height: 12px;
    margin-right: 9px;
    border-radius: 100%;
    background-color: $mainLightBlue;
    transition: all 0.25s;
  }

  .close {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(212, 224, 240, 1);
    background: #ffffff;
    border: 1px solid #d4e0f0;
    border-radius: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    svg {
      transform: rotate(45deg);
    }
  }

  &.selected {
    color: $mainBlack;

    .circle {
      background-color: $mainBlue;
    }
  }
}
